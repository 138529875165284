import React, { Fragment, useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  CardActionArea,
  CardActions,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import Container from "@mui/material/Container";
import { flexbox } from "@mui/system";
import Box from "@mui/material/Box";
import card from "./css/card.css";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VDRMediaMyFavouriteListAction, forumAction, vdrMediaAction, forumInsiderAction } from "../../../redux/actions/VdrAction";
import { FiSearch } from "react-icons/fi";
import DetailComponent from "./DetailComponent";
import { useNavigate } from "react-router-dom";
import VideoComponent from "./VideoComponent";
import { crtUserId, mediaInput } from "../../../constants/helpers";
import moment from "moment";
import SearchComponent from "./SearchComponent";

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';



const ResourcesComponent = ({insigths}) => {
  const [ItemList, setItemList] = useState([])
  const {
    data: { data: ForumData },
    loading: ForumLoading,
  } = useSelector((state) => state.ForumReducer);

  const {
    data: { data: ForumData3 },
    loading: ForumLoading3,
  } = useSelector((state) => state.ForumInsiderReducer);



  // console.log("================= ForumData3",ForumData3 );
  

  const [ForumData1, setForumData1] = React.useState([]);
  const [ForumDataFetch3, setForumDataFetch3] = React.useState([]);
  
  
  const [MostViewedVideos, setMostViewedVideos] = React.useState({});



  const dispatch = useDispatch();

  const [ActiveVideoType, setActiveVideoType] = useState(1);
  let buttonsArr = [
    {
      id: 1,
      text: "ALL",
      type: "all",
    },
    {
      id: 2,
      text: "Video",
      type: "/videos",
    },
    {
      id: 3,
      text: "Blogs",
      type: "/blogs",
    },
    {
      id: 3,
      text: "Newsletter",
      type: "/newsletter",
    },
    {
      id: 4,
      text: "Insights",
      type: "/insights",
    },
    {
        id:5,
        text:"My List",
        type:"/my-list"
    },
  ];
const { data: vdrMediaData, loading: vdrMediaLoading } = useSelector((state) => state.vdrMediaReducer);
const { data: VDRMediaMyFavouriteListData, loading: VDRMediaMyFavouriteListLoading } = useSelector((state) => state.VDRMediaMyFavouriteListReducer);

const myFavFun = () => {
  if(VDRMediaMyFavouriteListData?.Data && VDRMediaMyFavouriteListData.Data.length > 0){
      let a0 = VDRMediaMyFavouriteListData.Data.map((item)=>item?.videoId)
      setMyFavourite(a0);
    }
}


const [MyFavourite, setMyFavourite] = React.useState([]);
useEffect(() => {
  if (VDRMediaMyFavouriteListLoading) {
      let prams = { "webuserId": crtUserId() };
      dispatch(VDRMediaMyFavouriteListAction(prams));
  }

  if(!VDRMediaMyFavouriteListLoading){
    myFavFun()
  }
}, [dispatch, VDRMediaMyFavouriteListLoading])


const navigate = useNavigate();
  const chooseType = (item) => {
    navigate(item?.type)
    setActiveVideoType(item.id);
  };

  useEffect(() => {
    if (ForumLoading) {
      dispatch(forumAction({}));
    }
    if (!ForumLoading) {
      // console.log(ForumData, "forum>>>>>>>>>");
    }
    if (!ForumLoading) {
      if (ForumData?.presentation && ForumData?.company_blog) {
        let ForumDataArray = [
          ...ForumData?.presentation,
          ...ForumData?.company_blog,
        ];
        // let ForumDataArray0 = ForumDataArray.sort((a, b) => {
        //   return new Date(b.updated_at) - new Date(a.updated_at);
        // })
        let a00 = [];

        
        // console.log('ForumDataArray0 ?>>>>> ',ForumDataArray0)
        setForumData1(ForumDataArray);

        ForumDataArray.map(item=>{
          
          a00.push({ 
            // ...item, 
            title: item?.first_name,
            year: item?.slug
          })
        })

        setItemList(a00)

        // let mostViewed = ForumDataArray.sort((a, b) => {
        //   return b.view_count - a.view_count;
        // })
        
      }
    }
  }, [dispatch, ForumLoading]);




  useEffect(() => {
    if (ForumLoading3) {
      dispatch(forumInsiderAction({}));
    }
    if (!ForumLoading3) {
      // console.log(ForumData, "forum>>>>>>>>>");
    }

    if (!ForumLoading3) {
      if (ForumData3?.company_blog) {
        let ForumDataArray = [
          // ...ForumData?.presentation,
           ...ForumData3?.company_blog,
        ];
        // let ForumDataArray01 = ForumDataArray.sort((a,b)=> {
        //   let dateA = new Date(a.updated_at);
        //   let dateB = new Date(b.updated_at);
      
        //   return dateA - dateB; 
        // })

      // console.log( "ForumDataArray3333============= ?>>>>>", ForumDataArray01);
      
      

        let a00 = [];
        setForumDataFetch3(ForumDataArray);

        ForumDataArray.map(item=>{
          
          a00.push({ 
            // ...item, 
            title: item?.first_name,
            year: item?.slug
          })
        })

        setItemList(a00)

        // let mostViewed = ForumDataArray.sort((a, b) => {
        //   return b.view_count - a.view_count;
        // })
        
      }
    }
  }, [dispatch, ForumLoading3]);




    console.log("form data 3 =====================", ForumDataFetch3);
  // console.log("form  3 =====================", ForumData3);
  










  
  const [SlidesPerView, setSlidesPerView] = useState(5);
  const itemSize = () => {
       if (window.innerWidth < 480) {
            setSlidesPerView(1);
       } else if (window.innerWidth < 680) {
            setSlidesPerView(2);
       } else if (window.innerWidth < 880) {
            setSlidesPerView(3);
       } else {
            setSlidesPerView(5);
       }
  }

  useEffect(() => {
       itemSize();
       window.addEventListener('resize', function () {
            itemSize();
       });
  }, [SlidesPerView])


  const [SearchWidth, setSearchWidth] = useState(325);
  useEffect(() => {
    let a0 = document.querySelector('.vid-head-btn'); 
    console.log('a0.offsetWidth >> ', a0.offsetWidth)
    setSearchWidth(a0.offsetWidth)
  }, [])


  useEffect(() => {

    if (vdrMediaLoading && vdrMediaData.length === 0) {
        let a0 = mediaInput;
        a0 = {...a0, fromDate: moment().subtract(20, 'years').format('YYYY-MM-DD')}
        dispatch(vdrMediaAction(a0));
    }
    if (!vdrMediaLoading) {
        // sortVideos();
        // console.log('vdrMediaLoading <<<<>>>>> ', vdrMediaData)
        let aaaa = vdrMediaData?.Data;
          // aaaa = aaaa.sort((a,b)=>{
          //   return b?.videoViewsCount - a?.videoViewsCount
          // });
          setMostViewedVideos(aaaa)
        // console.log('aaaa >>>>> ', aaaa)
    }

}, [dispatch, vdrMediaLoading])

  

  useEffect(() => {}, [ForumData1]);
  return (
    <>
      <Box
        className="headerContainerFront"
        sx={{
          //   minHeight: "70px",
          height: "auto",
        }}
      >
        <Typography
          style={{ zIndex: 2 }}
          fontSize={"2.5em"}
          color="#000"
          fontWeight="700"
        >
          Resources
        </Typography>

        <Box className="vid-head-btn">
          {buttonsArr.map((item, index) => {
            return (
              <Fragment key={item.id}>
                <Button
                  onClick={(e) => {
                    chooseType(item);
                  }}
                  size="small"
                  className={`cst-btn ${
                    ActiveVideoType === item.id ? "contained" : "outlined"
                  }`}
                  variant={
                    ActiveVideoType === item.id ? "contained" : "outlined"
                  }
                >
                  {item.text}
                </Button>
              </Fragment>
            );
          })}
        </Box>

        <div className="vid-head-sec">
          <Box className="vid-head-search" mt={2}>
            {
              <div>
                <SearchComponent itemList={ItemList} SearchWidth={SearchWidth} />
              </div>
            }
          </Box>
        </div>
      </Box>
      <Box
        className="videoContainer"
        sx={{
          // marginTop: 5,
          // marginBottom: 5,
          paddingX:'2rem',
          maxWidth:'1300px',
          margin:"2.5rem auto"
        }}
      >
        <Grid container columnSpacing={4} rowSpacing={3} >
          
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ 
            fontSize:'2rem',
            textAlign:'center',
            lineHeight:.5,
            fontWeight:'600'
          }}>
            Latest
          </Typography>
        </Grid>
                
        </Grid>

        {/* latest box */}


   {
    ForumLoading3 ? <Box sx={{ marginTop:'1rem', textAlign: 'center' }}>
    <CircularProgress />
  </Box>
   :   <Box sx={{ marginTop: 5 }} className="dthche">
  <div className="external-buttons">
    <Grid container spacing={2}>
      {ForumDataFetch3 &&
        ForumDataFetch3.length > 0 &&
        ForumDataFetch3.slice(0,15).map((itm, i) => (
          <Grid item xs={12} sm={6} md={2.4} key={i}>
            <DetailComponent item={itm} insigths={insigths} />
          </Grid>
        ))}
    </Grid>
  </div>
</Box>
   }

             




        
        {/* latest box */}

        <Grid container columnSpacing={4} rowSpacing={3} sx={{
          marginTop:10}}   style={{ display: "none" }} >
          
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ 
            fontSize:'2rem',
            textAlign:'center',
            lineHeight:.5,
            fontWeight:'600'
          }}>
            Most Viewed
          </Typography>
        </Grid>
        <Grid item xs={12} margin={0} padding={0}>
          <Typography variant="h5" sx={{
            lineHeight:.5 
            }}>
            Blogs
          </Typography>
        </Grid>
          {/* { ForumData1 &&
            ForumData1.length > 0 &&
            ForumData1.sort((a, b) => {
              return b.view_count - a.view_count;
            }).map((itm, i) => {
              if (i < 3) {
                return (
                  <Grid key={i} item xs={4}>
                    <DetailComponent item={itm}/>
                  </Grid>
                );
              }
            })} */}
            
          </Grid>
          
        <Box sx={{ marginTop: 5}} style={{ display: "none" }} >
          <div className="carousel-container">
              <Swiper
                    className='external-buttons'
                    modules={[Navigation]}
                    spaceBetween={20}
                    slidesPerView={SlidesPerView}
                    navigation={false}
                    pagination={{ clickable: false }}
                    scrollbar={{ draggable: false }}
              >
                {ForumData1 &&
                  ForumData1.length > 0 &&
                  ForumData1.sort((a, b) => {
                    return b.view_count - a.view_count;
                  }).map((itm, i) => {
                    if (i < 5) {
                      return (
                        <SwiperSlide key={i} style={{

                        }}>
                        <DetailComponent item={itm}/>
                          
                        </SwiperSlide>
                        
                      );
                    }
                  })}


              </Swiper>
          </div>
        </Box>

        <Grid container columnSpacing={4} rowSpacing={3} sx={{ marginTop:5}}  style={{ display: "none" }} >
          
        <Grid item xs={12} >
          <Typography variant="h5" sx={{
            lineHeight:.5 
          }}>
            Videos
          </Typography>
        </Grid>
          {/* { MostViewedVideos &&
            MostViewedVideos.length > 0 &&
            MostViewedVideos.map((itm, i) => {
              if (i < 3) {
                console.log('itm >>>>> ', itm)
                return (
                  <Grid key={i} item xs={4}>
                    <VideoComponent MyFavourite={MyFavourite} item={itm}/>
                  </Grid>
                );
              }
            })} */}
        </Grid>
          
          <Box sx={{ marginTop: 5}}  style={{ display: "none" }} >
            <div className="carousel-container">
                <Swiper
                      className='external-buttons'
                      modules={[Navigation]}
                      spaceBetween={20}
                      slidesPerView={SlidesPerView}
                      navigation={false}
                      pagination={{ clickable: false }}
                      scrollbar={{ draggable: false }}
                >
                  { MostViewedVideos &&
                    MostViewedVideos.length > 0 &&
                    MostViewedVideos.map((itm, i) => {
                      if (i < 5) {
                        return (
                          <SwiperSlide key={i} style={{
  
                          }}>
                          {/* <DetailComponent item={itm}/> */}
                          <VideoComponent MyFavourite={MyFavourite} item={itm}/>
                            
                          </SwiperSlide>
                          
                        );
                      }
                    })}
  
  
                </Swiper>
            </div>
          </Box>
      </Box>
    </>
  );
};

export default ResourcesComponent;
